import { gql } from "@apollo/client";
import { gtag, install } from "ga-gtag";

import apolloClient from "./apolloClient";
import { GA_MEASURE_ID, ONSEN_APP } from "./environment";
import { progressBar } from "./useProgressBar";

export function initGA() {
  if (ONSEN_APP) return;
  install(GA_MEASURE_ID, {
    send_page_view: false,
  });
}

const gaEventSharedParams = {};

export function gaSetSharedEventParams(params) {
  const oldOrgId = gaEventSharedParams.org_id;
  Object.assign(gaEventSharedParams, params);
  const newOrgId = gaEventSharedParams.org_id;
  if (newOrgId !== oldOrgId) {
    progressBar(async () => {
      let orgName = null;
      if (newOrgId) {
        const result = await apolloClient.query({
          query: gql`
            query gaSetSharedEventParams($id: ID!) {
              org(id: $id) {
                id
                name
              }
            }
          `,
          variables: { id: newOrgId },
          fetchPolicy: "cache-first",
        });
        orgName = result.data?.org?.name;
      }
      gaEventSharedParams.org_name = orgName;
    });
  }
}

export function gaEvent(eventName, params) {
  // TODO: gtag won't work in Capacitor runtime as for now. Implement this through firebase SDK instead.
  if (ONSEN_APP) return;
  return gtag("event", eventName, {
    ...gaEventSharedParams,
    ...params,
  });
}
