import { gql } from "@apollo/client";
import Stack, { PageContentStack } from "atoms/Stack";
import { COLOR_ASSIST, COLOR_BLOCK, COLOR_POINT, COLOR_REBOUND, COLOR_STEAL } from "helpers/colors";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import PersonCard, { PersonCardFragment, PersonCardStat } from "molecules/PersonCard";
import Section from "molecules/Section";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import { TeamPageTitle } from "shared/PageTitle";

import TeamPagesHeader from "./team/TeamPagesHeader";

export default function TeamRosterPage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });
  const [permissionData] = useData(
    gql`
      query TeamPagesHeader_permission($teamId: ID!) {
        team(id: $teamId) {
          id
          org {
            id
          }
          canOnsenTeamPage
        }
      }
    `,
    { teamId },
  );
  gaSetSharedEventParams({ org_id: permissionData?.team?.org?.id });

  const [data] = useData(
    gql`
      query TeamRosterPage($teamId: ID!) {
        team(id: $teamId) {
          id
          players: teamMembers(role: "player") {
            id
            playerNumber
            person {
              id
              ...PersonCardFragment
            }
            gamesCount
            pointsPerGame
            reboundsPerGame
            assistsPerGame
            blocksPerGame
            stealsPerGame
          }
          coaches: teamMembers(role: "coach") {
            id
            person {
              id
              ...PersonCardFragment
            }
          }
        }
      }
      ${PersonCardFragment}
    `,
    { teamId },
  );

  if (permissionData && !permissionData.team?.canOnsenTeamPage) return <NotFoundPage />;

  return (
    <>
      <TeamPageTitle title="Roster" />
      <TeamPagesHeader teamId={teamId} tab="roster" />
      <PageContentStack>
        <Section title="Players">
          <Stack horizontal>
            {data?.team?.players.length === 0 && <div>No players</div>}
            {data?.team?.players.map((player) => (
              <PersonCard
                playerNumber={player.playerNumber || "?"}
                key={player.id}
                person={player.person}
                stats={
                  <>
                    <PersonCardStat label="Games" value={player.gamesCount} />
                    <PersonCardStat label="PPG" color={COLOR_POINT} value={player.pointsPerGame} />
                    <PersonCardStat label="RPG" color={COLOR_REBOUND} value={player.reboundsPerGame} />
                    <PersonCardStat label="APG" color={COLOR_ASSIST} value={player.assistsPerGame} />
                    <PersonCardStat label="BPG" color={COLOR_BLOCK} value={player.blocksPerGame} />
                    <PersonCardStat label="SPG" color={COLOR_STEAL} value={player.stealsPerGame} />
                  </>
                }
              />
            ))}
          </Stack>
        </Section>
        <Section title="Coaches">
          <Stack horizontal>
            {data?.team?.coaches.length === 0 && <div>No coaches</div>}
            {data?.team?.coaches.map((coach) => (
              <PersonCard key={coach.id} person={coach.person} />
            ))}
          </Stack>
        </Section>
      </PageContentStack>
    </>
  );
}
