import { gql } from "@apollo/client";
import { alpha, Button, Paper } from "@mui/material";
import { ButtonBase } from "@mui/material";
import DateTime from "atoms/DateTime";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { Trophy } from "mdi-material-ui";
import PotwVideoPlayerDialog from "pages/video_player/PotwVideoPlayerDialog";
import React, { useState } from "react";

import getCompetitionStyle from "./getCompetitionStyle";

export const PotwCompetitionCardFragment = gql`
  fragment PotwCompetitionCardFragment on PotwCompetition {
    id
    competitionType
    name
    completed
    startDate
    endDate
    org {
      id
      name
    }
    videoUrl
  }
`;

export default function PotwCompetitionCard({ potwCompetition }) {
  const competitionStyle = getCompetitionStyle(potwCompetition.competitionType);
  const [dialogOpen, dialogOpenSet] = useState(false);

  return (
    <>
      <PotwVideoPlayerDialog
        open={dialogOpen}
        onClose={() => dialogOpenSet(false)}
        potwCompetitionId={potwCompetition.id}
      />
      <Paper
        elevation={10}
        style={{
          borderRadius: 10,
          overflow: "hidden",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "stretch",
        }}
      >
        <Stack
          noGap
          style={{
            flex: "1 1 auto",
            backgroundImage: `url(${competitionStyle.backgroundImage})`,
            color: COLOR_WHITE,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            fontWeight: 100,
            fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
            fontSize: 24,
            backgroundColor: COLOR_BLACK,
          }}
        >
          <Stack
            padding
            element={<ButtonBase href={`/potw/competitions/${potwCompetition.id}`} />}
            horizontal
            alignItemsStart
            style={{
              flex: "1 1 auto",
              textTransform: "uppercase",
            }}
          >
            <img src={competitionStyle.logoImage} style={{ height: "3em" }} alt={competitionStyle.title} />
            <div>
              <div
                style={{
                  color: competitionStyle.color,
                }}
              >
                {competitionStyle.title}
              </div>
              <div style={{ fontSize: "2em" }}>{potwCompetition.name}</div>
              <div>{potwCompetition.org.name}</div>
            </div>
          </Stack>
          <Stack
            padding
            dense
            alignItemsCenter
            style={{
              fontSize: ".8em",
              color: COLOR_WHITE,
              backgroundColor: alpha(COLOR_BLACK, 0.5),
            }}
          >
            {!potwCompetition.completed && (
              <div>
                Voting closes <DateTime value={potwCompetition.endDate} variant="short_date" />
              </div>
            )}
            {potwCompetition.videoUrl && (
              <ButtonBase disableRipple onClick={() => dialogOpenSet(true)}>
                <Trophy />
                Watch Top 5
              </ButtonBase>
            )}
          </Stack>
        </Stack>
        <Button fullWidth size="large" href={`/potw/competitions/${potwCompetition.id}`}>
          {potwCompetition.completed ? <>Watch All Entries</> : <>Vote Now</>}
        </Button>
      </Paper>
    </>
  );
}
