import { gql } from "@apollo/client";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import { PotwPageTitle } from "shared/PageTitle";

import PotwVideoPlayer, { PotwVideoPlayerFragment } from "./video_player/PotwVideoPlayer";

export default function PotwCompetitionVideoPage({ potwCompetitionId }) {
  const [data] = useData(
    gql`
      query PotwCompetitionVideoPage($potwCompetitionId: ID!) {
        potwCompetition(id: $potwCompetitionId) {
          id
          org {
            id
          }
          ...PotwVideoPlayerFragment
        }
      }
      ${PotwVideoPlayerFragment}
    `,
    { potwCompetitionId },
  );

  if (data && !data.potwCompetition) return <NotFoundPage />;
  gaSetSharedEventParams({ org_id: data?.potwCompetition?.org?.id });

  return (
    <>
      <PotwPageTitle title="Results" />
      <PotwVideoPlayer potwCompetitionId={potwCompetitionId} potwCompetition={data?.potwCompetition} />
    </>
  );
}
