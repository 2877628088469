import { gql } from "@apollo/client";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import PageTitle from "shared/PageTitle";

import ReelPlayer, { ReelPlayerFragment } from "./video_player/ReelPlayer";

export default function ReelPage({ reelViewId }) {
  const [data] = useData(
    gql`
      query ReelPage($reelViewId: ID!) {
        reelView(id: $reelViewId) {
          id
          title
          ...ReelPlayerFragment
          org {
            id
          }
        }
      }
      ${ReelPlayerFragment}
    `,
    { reelViewId },
  );
  if (data && !data.reelView) return <NotFoundPage />;
  gaSetSharedEventParams({ org_id: data?.reelView?.org?.id });

  return (
    <>
      <PageTitle title="Reel" />
      <ReelPlayer reelViewId={reelViewId} reelView={data?.reelView} />
    </>
  );
}
