import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import React from "react";
import NotFoundPage from "shared/NotFoundPage";
import { PersonPageTitle } from "shared/PageTitle";

import PersonCareerStats from "./person/PersonCareerStats";
import PersonGamesStats from "./person/PersonGamesStats";
import PersonPagesHeader from "./person/PersonPagesHeader";

export default function PersonPage({ personId }) {
  useEnforceAuth({ enforceAuthenticated: true });
  const [data] = useData(
    gql`
      query PersonPage($personId: ID!) {
        person(id: $personId) {
          id
          org {
            id
          }
        }
      }
    `,
    { personId },
  );
  gaSetSharedEventParams({ org_id: data?.person?.org?.id });

  if (data && !data.person) return <NotFoundPage />;

  return (
    <>
      <PersonPageTitle />
      <PersonPagesHeader personId={personId} tab="profile" />
      <PageContentStack>
        <PersonCareerStats personId={personId} />
        <PersonGamesStats personId={personId} />
      </PageContentStack>
    </>
  );
}
