import { gql } from "@apollo/client";
import { IconButton } from "@mui/material";
import DateTime from "atoms/DateTime";
import { COLOR_BLACK, COLOR_WHITE } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { Close } from "mdi-material-ui";
import React, { useState } from "react";

import getCompetitionStyle from "./getCompetitionStyle";

export const PotwCompetitionBannerFragment = gql`
  fragment PotwCompetitionBannerFragment on PotwCompetition {
    id
    name
    completed
    competitionType
    startDate
    endDate
    org {
      id
      name
    }
  }
`;

export default function PotwCompetitionBanner({ potwCompetition }) {
  const competitionStyle = potwCompetition && getCompetitionStyle(potwCompetition.competitionType);
  const [hidden, hiddenSet] = useState(false);

  if (hidden) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: COLOR_BLACK,
        color: COLOR_WHITE,
        backgroundImage: competitionStyle?.backgroundImage && `url(${competitionStyle.backgroundImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        fontWeight: 100,
        fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
        fontSize: 24,
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
        padding: 10,
        textTransform: "uppercase",
        position: "relative",
      }}
    >
      <img src={competitionStyle?.logoImage} alt={competitionStyle?.title} style={{ height: "3em" }} />
      <div>
        <div
          style={{
            color: competitionStyle?.color,
          }}
        >
          {competitionStyle?.title}
        </div>
        <div style={{ fontSize: "2.5em" }}>{potwCompetition?.name}</div>
      </div>
      <div>
        <div>
          <DateTime value={potwCompetition?.startDate} variant="short_date" /> -{" "}
          <DateTime value={potwCompetition?.endDate} variant="short_date" />
        </div>
        <div>{potwCompetition?.org.name}</div>
      </div>
      <IconButton
        style={{
          position: "absolute",
          top: 10,
          right: 10,
        }}
        title="Hide"
        color="inherit"
        size="small"
        onClick={() => hiddenSet(true)}
      >
        <Close />
      </IconButton>
    </div>
  );
}
