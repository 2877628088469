import { gql } from "@apollo/client";
import { alpha, CircularProgress } from "@mui/material";
import FetchMoreButton from "atoms/FetchMoreButton";
import Stack from "atoms/Stack";
import VideoPlayer from "atoms/VideoPlayer";
import { COLOR_BLACK, COLOR_PRIMARY_DARKER, COLOR_SECONDARY, COLOR_WHITE } from "helpers/colors";
import { gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import usePseudoStyle from "helpers/usePseudoStyle";
import { ArrowLeft, Play, PlaylistPlay, Restore, SkipNext, SkipPrevious } from "mdi-material-ui";
import ControlBar from "molecules/ControlBar";
import ControlBarContent from "molecules/ControlBarContent";
import ControlButton from "molecules/ControlButton";
import React, { useLayoutEffect, useRef, useState } from "react";
import NotFoundPage from "shared/NotFoundPage";
import { PotwPageTitle } from "shared/PageTitle";

import PotwCompetitionBanner, { PotwCompetitionBannerFragment } from "./potw/PotwCompetitionBanner";
import PotwCompetitionEmptyDialog from "./potw/PotwCompetitionEmptyDialog";
import PotwEntryButton, { PotwEntryButtonFragment } from "./potw/PotwEntryButton";
import PotwEntryDetails, { PotwEntryDetailsFragment } from "./potw/PotwEntryDetails";
import PotwEntryOverlay, { PotwEntryOverlayFragment } from "./potw/PotwEntryOverlay";
import FullscreenButton from "./video_player/FullscreenButton";
import VolumeButton from "./video_player/VolumeButton";

export default function PotwCompetitionPage({ potwCompetitionId }) {
  useEnforceAuth({ enforceAuthenticated: true });

  const [searchTerm, searchTermSet] = useState("");
  const [data, dataMeta] = useData(
    gql`
      query PotwCompetitionPage($potwCompetitionId: ID!, $offset: Int) {
        potwCompetition(id: $potwCompetitionId) {
          id
          live
          org {
            id
          }
          ...PotwCompetitionBannerFragment
          potwEntriesCount
          potwEntries(offset: $offset, limit: 10) {
            id
            videoUrl
            title
            subtitle
            ...PotwEntryButtonFragment
            ...PotwEntryDetailsFragment
            ...PotwEntryOverlayFragment
          }
        }
      }
      ${PotwCompetitionBannerFragment}
      ${PotwEntryDetailsFragment}
      ${PotwEntryButtonFragment}
      ${PotwEntryOverlayFragment}
    `,
    { potwCompetitionId },
  );
  gaSetSharedEventParams({ org_id: data?.potwCompetition?.org?.id });

  const videoRef = useRef();
  const [audioEnabled, audioEnabledSet] = useState(null);
  const [looping, loopingSet] = useState(false);
  const [showFullList, showFullListSet] = useState(true);
  const [overlayWaiting, overlayWaitingSet] = useState(false);
  const [playing, playingSet] = useState(false);
  const [playProgress, playProgressSet] = useState(0);
  const [playProgressEntrieId, playProgressEntrieIdSet] = useState(null);
  const [currentEntryIndex, currentEntryIndexSet] = useState(0);
  let entries = data?.potwCompetition?.potwEntries;
  if (searchTerm)
    entries = entries?.filter(
      (entry) =>
        entry.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        entry.subtitle.toLowerCase().includes(searchTerm.toLowerCase()),
    );

  const currentEntry = entries && entries[currentEntryIndex];
  let nextEntryIndex, prevEntryIndex;
  if (entries) {
    nextEntryIndex = currentEntryIndex + 1;
    prevEntryIndex = currentEntryIndex - 1;
    if (looping) {
      nextEntryIndex = nextEntryIndex % entries.length;
      prevEntryIndex = (prevEntryIndex + entries.length) % entries.length;
    }
  }
  const nextEntry = entries && entries[nextEntryIndex];
  const prevEntry = entries && entries[prevEntryIndex];

  useLayoutEffect(() => {
    overlayWaitingSet(false);
  }, [currentEntryIndex]);

  const searchBoxPseudoStyle = usePseudoStyle({
    "::placeholder": `
      color: ${alpha(COLOR_WHITE, 0.6)};
      `,
  });

  if (data && !data.potwCompetition) return <NotFoundPage />;

  return (
    <>
      <PotwPageTitle title="Watch" />
      <div
        style={{
          flex: "1 0 auto",
          height: 400, // Minimum height
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "stretch",
          outline: "none",
        }}
        tabIndex={0}
        onKeyDown={(event) => {
          if (!videoRef.current) return;
          if (event.key === "ArrowLeft") {
            videoRef.current.currentTime -= 3;
            overlayWaitingSet(false);
          }
          if (event.key === "ArrowRight") {
            videoRef.current.currentTime += 3;
            overlayWaitingSet(false);
          }
        }}
      >
        <PotwCompetitionBanner potwCompetition={data?.potwCompetition} />
        <ControlBar style={{ flex: "0 0 auto" }}>
          <ControlButton icon={<ArrowLeft />} href="/potw" />
          <ControlBarContent>
            <PotwEntryDetails potwEntry={currentEntry} />
          </ControlBarContent>
          <ControlButton icon={<Restore />} label="Loop" onClick={() => loopingSet((l) => !l)} inverse={looping} />
          <ControlButton
            icon={<SkipPrevious />}
            label="Previous"
            disabled={!prevEntry}
            onClick={() => currentEntryIndexSet(prevEntryIndex)}
          />
          <ControlButton
            iconEnd={<SkipNext />}
            label="Next"
            disabled={!nextEntry}
            onClick={() => currentEntryIndexSet(nextEntryIndex)}
          />
          <ControlButton
            icon={<PlaylistPlay />}
            label="See full list"
            inverse={showFullList}
            onClick={() => showFullListSet((s) => !s)}
          />
        </ControlBar>
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            flexFlow: "row wrap",
            backgroundColor: COLOR_BLACK,
            color: COLOR_WHITE,
            justifyContent: "stretch",
            alignItems: "stretch",
          }}
        >
          <div style={{ flex: "999 1 400px", position: "relative" }}>
            {currentEntry && !currentEntry.videoUrl && (
              <CircularProgress
                color="inherit"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
            {currentEntry?.videoUrl && (
              <VideoPlayer
                disablePictureInPicture
                playsInline
                autoPlay
                onAudioEnabledChange={audioEnabledSet}
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  objectFit: "contain",
                }}
                videoRef={videoRef}
                src={currentEntry?.videoUrl}
                onPlay={() => {
                  playingSet(true);
                  overlayWaitingSet(false);
                }}
                onPause={() => playingSet(false)}
                onEnded={() => {
                  if (data?.potwCompetition?.live) {
                    overlayWaitingSet(true);
                  } else {
                    if (nextEntryIndex) currentEntryIndexSet(nextEntryIndex);
                  }
                }}
                onTimeUpdate={(event) => {
                  const { currentTime, duration } = event.currentTarget;
                  const playProgress = currentTime / duration;
                  playProgressEntrieIdSet(currentEntry?.id);
                  playProgressSet(playProgress);
                }}
                onClick={() => {
                  if (videoRef.current.paused) videoRef.current.play();
                  else videoRef.current.pause();
                }}
              />
            )}
            {currentEntry?.videoUrl && (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  opacity: playing ? 0 : 1,
                  transition: "opacity 0.3s",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (videoRef.current.paused) videoRef.current.play();
                  else videoRef.current.pause();
                }}
              >
                <Play
                  fontSize="inherit"
                  style={{
                    color: COLOR_SECONDARY,
                    fontSize: 64,
                  }}
                />
              </div>
            )}
            {data?.potwCompetition?.live && (
              <PotwEntryOverlay
                style={{
                  position: "absolute",
                  zIndex: 1,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
                potwEntry={currentEntry}
                waiting={overlayWaiting}
                onWaitingEnd={() => {
                  if (nextEntry) currentEntryIndexSet(nextEntryIndex);
                }}
                onReplayClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime = 0;
                    videoRef.current.play();
                  }
                  overlayWaitingSet(false);
                }}
                onNextClick={() => {
                  if (nextEntry) currentEntryIndexSet(nextEntryIndex);
                }}
              />
            )}
            <Stack
              style={{
                zIndex: 1,
                position: "absolute",
                right: 0,
                bottom: 0,
              }}
              horizontal
              dense
              padding
              alignItemsCenter
            >
              <VolumeButton enabled={audioEnabled} />
              <FullscreenButton video={videoRef.current} />
            </Stack>
          </div>
          {showFullList && (
            <div
              style={{
                flex: "1 1 auto",
                width: 300,
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexFlow: "column nowrap",
                  alignItems: "stretch",
                  overflowY: "auto",
                  backgroundColor: COLOR_PRIMARY_DARKER,
                }}
              >
                <input
                  className={searchBoxPseudoStyle.className}
                  type="search"
                  style={{
                    backgroundColor: COLOR_SECONDARY,
                    color: COLOR_WHITE,
                    border: "none",
                    display: "block",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: 20,
                    height: "2em",
                    outline: "none",
                  }}
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(event) => {
                    searchTermSet(event.target.value);
                  }}
                />
                {entries?.length === 0 && !!searchTerm && (
                  <div
                    style={{
                      padding: 20,
                      textAlign: "center",
                      color: COLOR_WHITE,
                    }}
                  >
                    No results found.
                  </div>
                )}
                {entries?.map((entry, entryIndex) => (
                  <PotwEntryButton
                    key={entry.id}
                    active={entry.id === currentEntry?.id}
                    playProgress={
                      entry.id === playProgressEntrieId && entry.id === currentEntry?.id ? playProgress : null
                    }
                    onClick={() => currentEntryIndexSet(entryIndex)}
                    potwEntry={entry}
                  />
                ))}
                <FetchMoreButton
                  autoFetch
                  dataMeta={dataMeta}
                  rows={data?.potwCompetition?.potwEntries}
                  rowsCount={data?.potwCompetition?.potwEntriesCount}
                />
              </div>
            </div>
          )}
        </div>
        {entries?.length === 0 && !searchTerm && <PotwCompetitionEmptyDialog />}
      </div>
    </>
  );
}
